<template>
  <div class="g-section">
    <div class="g-wrap flex-column">
      <content-header :title-list="titleList"></content-header>
      <div class="from-content" v-loading="loading">
        <div class="flex u-row-between u-col-center top">
          <el-radio-group class="switch" v-model="queryParams.Status" size="medium" @change="handleChange">
            <el-radio-button :label="ResumeDeliveryStatusEnum.Delivery">
              <span class="el-icon-message"></span>
              <span>新到简历</span>
            </el-radio-button>
            <el-radio-button :label="ResumeDeliveryStatusEnum.Candidate">
              <span class="el-icon-user"></span>
              <span>候选人</span>
            </el-radio-button>
            <el-radio-button :label="ResumeDeliveryStatusEnum.Interview">
              <span class="el-icon-date"></span>
              <span>已约面试</span>
            </el-radio-button>
            <el-radio-button :label="ResumeDeliveryStatusEnum.Admit">
              <span class="el-icon-coordinate"></span>
              <span>录取</span>
            </el-radio-button>
            <el-radio-button :label="ResumeDeliveryStatusEnum.NotSuitable">
              <span class="el-icon-circle-close"></span>
              <span>不合适</span>
            </el-radio-button>
            <!-- <el-radio-button :label="ResumeDeliveryStatusEnum.Archive">
              <span class="el-icon-circle-close"></span>
              <span>已归档简历</span>
            </el-radio-button> -->
          </el-radio-group>
          <div class="flex">
            <el-select v-if="false" v-model="queryParams.OwnerType" placeholder="请选择">
              <el-option v-for="item in ownerTypeOptions" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
            <div v-if="true">
              <span>岗位：</span>
              <el-select v-model="queryParams.RecruitJobId" placeholder="请选择岗位" @change="jobChange" :clearable="true">
                <el-option v-for="item in jobOptions" :key="item.value" :label="item.label" :value="item.value" />
              </el-select>
            </div>

            <!-- <div class="tag-search">
              <span style="margin-right:10px;">分类:</span>
              <el-select v-model="TagId" clearable placeholder="请选择" @change="loadData(true)">
                <el-option v-for="item in unitTagList" :key="item.Value" :label="item.Text" :value="item.Value" />
              </el-select>
            </div> -->
            <!-- <el-input class="keyword ml-8" placeholder="请输入关键字查询" v-model="queryParams.KeyWord" size="small"/> -->
            <!-- <el-button class="ml-8" @click="search">搜索</el-button> -->
          </div>
        </div>
        <div class="bottom">
          <list text="暂无信息" :dataList="list" :totalCount="totalCount" :loading="loading" @loadMore="load">
            <talent-list :list="list" :button-submiting="buttonSubmiting" :is-delivered="true" @add-candidate="addCandidate" @add-not-suitable="addNotSuitable" @add-interview="addInterview" @add-admit="addAdmit"
              @add-archive="addArchive" />
          </list>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { addAdmit, addArchive, addCandidate, addInterview, addNotSuitable, getPageList } from "@/api/resumedelivery";
import ContentHeader from "@/components/Common/ContentHeader";
import List from "@/components/Common/List";
import TalentList from "@/components/TalentList";
import { ChatMessageTypeEnum, InterviewTypeEnum, ResumeDeliveryStatusEnum, StaffTypeEnum, UserTypeEnum } from '@/utils/constant';
import { getMyList, interview } from '@/api/job';
import { sendMessage } from '@/api/chat';
export default {
  components: {
    ContentHeader,
    List,
    TalentList
  },
  data() {
    return {
      ResumeDeliveryStatusEnum: ResumeDeliveryStatusEnum,
      queryParams: {
        Status: 0,
        KeyWord: "",
        OwnerType: null,
        PageSize: 10,
        PageIndex: 0,
        RecruitJobId: null,
        UnitId: undefined
      },
      ownerTypeOptions: [
        {
          value: null,
          label: "全部状态"
        }
      ],
      list: [],
      totalCount: 0,
      loading: false,
      jobOptions: [],
      buttonSubmiting: false
    };
  },
  computed: {
    ...mapGetters(['user']),
    ...mapGetters(["employee"]),
    titleList: function ({ queryParams: { RecruitJobId } = {}, jobOptions = [] }) {
      const title = [
        {
          text: "简历管理",
          isCur: false,
          link: ""
        },
        {
          text: "",
          isCur: true,
          link: ""
        }
      ];
      if (jobOptions.length && RecruitJobId) {
        const job = jobOptions.find(item => item.value === RecruitJobId);
        if (job) {
          title[1].text = job.label;
        }
      }
      return title;
    }
  },
  created() {
    //todo:默认显示新收到的简历
    this.queryParams.Status = this.$route.params.status === undefined ? 0 : this.$route.params.status;
    this.queryParams.RecruitJobId = this.$route.params.jobId;
    if (this.$route.matched.some(({ path }) => path.includes("enterprise"))) {
      const { Employer: { Id } = {} } = this.employee.find(({ StaffType }) => StaffType === StaffTypeEnum.Enterprise) || {};
      this.queryParams.UnitId = Id;

    } else if (this.$route.matched.some(x => x.path.includes("school"))) {
      const { Employer: { Id } = {} } = this.employee.find(({ StaffType }) => StaffType === StaffTypeEnum.School) || {};
      this.queryParams.UnitId = Id;
    }
    // if (this.queryParams.RecruitJobId) {
    this.load();
    // }
    this.initJobSelect();
  },
  methods: {
    initJobSelect() {
      getMyList().then(({ Result }) => {
        this.jobOptions = Result.map(item => ({
          label: item.Text,
          value: item.Value
        }));
        if (!this.queryParams.RecruitJobId && this.jobOptions.length) {
          // this.queryParams.RecruitJobId = this.jobOptions[0].value;
          // this.load();
        }
      });
    },
    handleChange(val) {
      this.queryParams.Status = val;
      this.queryParams.PageIndex = 0;
      this.list = [];
      this.totalCount = 0;
      this.load();
    },
    load() {
      this.loading = true;
      this.queryParams.PageIndex++;
      const param = Object.assign({}, this.queryParams)
      getPageList(param).then(({ Result: { Data, TotalCount } }) => {
        this.list = this.list.concat(Data.map(({ Id, Status, RecruitJobId, RecruitJobName, RecruitEmployerName, IsArchived, ArchiveId, Resume }) => ({ DeliveryId: Id, DeliveryStatus: Status, IsArchived, ArchiveId, RecruitJobId, RecruitJobName, RecruitEmployerName, ...Resume })));
        this.totalCount = TotalCount;
        this.loading = false;
      });
    },
    search() {
      this.queryParams.PageIndex = 0;
      this.list = [];
      this.totalCount = 0;
      this.load();
    },
    jobChange() {
      this.queryParams.PageIndex = 0;
      this.list = [];
      this.totalCount = 0;
      this.load();
    },
    changeResumeDeliveryStatus(param = {}) {
      let task;
      switch (param.Status) {
        case ResumeDeliveryStatusEnum.Candidate:
          task = addCandidate(param.DeliveryId);
          break;
        case ResumeDeliveryStatusEnum.Interview:
          task = addInterview(param);
          break;
        case ResumeDeliveryStatusEnum.NotSuitable:
          task = addNotSuitable(param.DeliveryId);
          break;
        case ResumeDeliveryStatusEnum.Admit:
          task = addAdmit(param.DeliveryId);
          break;
        case ResumeDeliveryStatusEnum.Archive:
          task = addArchive(param.DeliveryId);
          break;
        default:
          break;
      }
      if (task) {
        this.buttonSubmiting = true;
        task.then(({ Result }) => {
          if (param.Status === ResumeDeliveryStatusEnum.Archive) {
            this.list.forEach(item => {
              if (item.DeliveryId === param.DeliveryId) {
                item.IsArchived = true;
                item.ArchiveId = Result.Id;
              }
            });
          } else {
            this.list = this.list.filter(item => item.DeliveryId !== param.DeliveryId);
            this.totalCount--;
            if (param.SendMessage && param.Status === ResumeDeliveryStatusEnum.Interview) {
              this.goChat({ InterviewId: Result.Id, ...param });
            }
          }
          this.buttonSubmiting = false;
        }).catch(_ => {
          this.$message.error('操作失败')
          this.buttonSubmiting = false;
        });
      } else {
        this.$message.error('操作失败');
      }
    },
    addCandidate(param = {}) {
      this.changeResumeDeliveryStatus({ ...param, Status: ResumeDeliveryStatusEnum.Candidate });
    },
    addInterview(param = {}) {
      this.changeResumeDeliveryStatus({ ...param, Status: ResumeDeliveryStatusEnum.Interview, SendMessage: true });
    },
    addNotSuitable(param = {}) {
      this.changeResumeDeliveryStatus({ ...param, Status: ResumeDeliveryStatusEnum.NotSuitable });
    },
    addAdmit(param = {}) {
      //todo:暂时取消录取发消息
      this.changeResumeDeliveryStatus({ ...param, Status: ResumeDeliveryStatusEnum.Admit, SendMessage: false });
    },
    addArchive(param = {}) {
      //todo:暂时取消录取发消息
      this.changeResumeDeliveryStatus({ ...param, Status: ResumeDeliveryStatusEnum.Archive });
    },
    goChat({ InterviewId, Type, Time, UserId, Address, Remark, ResumeId }) {
      Type = `\n类型：${Type === InterviewTypeEnum.Online ? '线上' : '线下'}`;
      Time = `\n时间：${Time}`;
      Address = Address ? `\n地点：${Address}` : '';
      Remark = Remark ? `\n备注：${Remark}` : '';
      const message = {
        AtUserId: UserId,
        ToUserId: UserId,
        Message: `面试邀请${Type}${Time}${Address}${Remark}`,
        TargetId: InterviewId,
        Type: ChatMessageTypeEnum.interview,
        UserType: UserTypeEnum.JobRecruiter
      };
      sendMessage(message).then(({ ChatGroupId }) => {
        this.$router.push({ name: "EnterpriseChat", params: { ChatGroupId, ResumeId } }); // 只能用 name
      }).catch((e) => { });
    }
  }
};
</script>
<style lang="scss" scoped>
.g-section {
  background: #f5f5f5;
}
.switch {
  ::v-deep .el-radio-button__inner {
    line-height: 18px;
    font-size: 14px;
    // background: red;
  }
  ::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    background: rgba(1, 188, 119, 0.08);
    color: #598DF3;
  }
}
.keyword {
  ::v-deep .el-input__inner {
    height: 40px;
    line-height: 40px;
  }
}
.from-content {
  background: #fff;
  // margin: 8px 0;
  border-radius: 8px;
}
.top {
  padding: 24px 24px 16px 24px;
  border-bottom: 1px solid #eee;
}
.bottom {
  padding: 16px 24px;
}
</style>
